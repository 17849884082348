<template>
  <CCardBody>

    <alert-section
      :successAlertMessage="successAlertMessage"
      :dismissSecs="dismissSecs"
      :dismissSuccessAlert="dismissSuccessAlert"
      :errorAlertMessage="errorAlertMessage"
      :showErrorAlert="showErrorAlert"
    />

    <!-- Filters -->
    <CCard class="filters" accent-color="warning">
      <CCardHeader
        role="button"
        class="text-warning shadow-none card-header"
        @click="resetFilters"
      >
        <strong class="m-0"><CIcon :name="`cil-filter${$store.state.filterShow ? '-x' : ''}`" /> Filters</strong>
        <div class="card-header-actions">
          <CIcon :name="`cil-chevron-${$store.state.filterShow ? 'bottom' : 'top'}`"/>
        </div>
      </CCardHeader>

      <CCollapse :show="$store.state.filterShow">
        <CCardBody class="p-2">
          <div class="d-flex flex-md-row flex-column" style="gap: 0.5rem;">
            <!-- Company Filter -->
            <div class="flex-grow-1">
              <v-select
                class="v-select-filter"
                placeholder="Select company.."
                v-model="selectedCompany"
                :options="allCompanies"
                @input="companyFilter"
              />
            </div>

            <!-- Pagination -->
            <div v-if="pages > 1">
              <CPagination
                align="end"
                :dots='false'
                :pages="pages"
                :active-page.sync="activePage"
              />
            </div>
          </div>
        </CCardBody>
      </CCollapse>
    </CCard>

    <CDataTable
      striped
      hover
      :items="loadedItems"
      :fields="fields"
      :sorter="{ external: true, resetable: true }"
      :table-filter="{ external: true, lazy: true, placeholder: '...' }"
      :tableFilterValue.sync="search"
      :column-filter="{ external: true, lazy: true }"
      :itemsPerPageSelect="{
        external: true,
        values: [5, 15, 25, 50, 100, 250, 500],
      }"
      :items-per-page.sync="itemsPerPage"
      :loading="loading"
      :noItemsView="{
        noResults: 'No filtering results are available!',
        noItems: 'No payments found!',
      }"
      @update:sorter-value="sorterValue"
      @update:table-filter-value="tableSearch"
      @pagination-change="paginationChange"
    >

      <!-- ID -->
      <template #id="{item}">
        <td>
          #{{ item?.id }}
        </td>
      </template>

      <!-- Company -->
      <template #company="{item}">
        <td>
          #{{ item.company?.id }}
          <CLink class="font-weight-bold">
            {{ item.company?.name }}
          </CLink>
        </td>
      </template>

      <!-- Invoice Number -->
      <template #invoice="{item}">
        <td>
          {{ item.invoice.ivNr }}
        </td>
      </template>

      <!-- Amount -->
      <template #apamount="{item}">
        <td class="font-weight-bold">
          {{ item.apamount | toCurrency }}
        </td>
      </template>

      <!-- Date (apdate) Filter -->
      <template #apdate-filter>
        <input
          type="date"
          v-model="dateFilter"
          class="w-min"
        />
      </template>

      <!-- Date (apdate) -->
      <template #apdate="{item}">
        <td>
          {{ item.apdate }}
        </td>
      </template>

      <!-- Notes -->
      <template #apnote="{item}">
        <td>
          {{ item.apnote }}
        </td>
      </template>

      <!-- Actions -->
      <template #actions="{ item, index }">
        <td class="p-2">
          <CButton
            size="sm"
            color="danger"
            shape="pill"
            @click="deleteItem(item, index)"
          >
            <CIcon name="cil-trash" />
          </CButton>

          <CButton
            v-if="!item.invoice.is_paid"
            size="sm"
            color="warning"
            shape="pill"
            class="ml-1"
            v-c-tooltip="{
              html: true,
              content: 'Add Payment',
              placement: 'right',
            }"
            @click="addPayment(item.invoice)"
          >
            <CIcon name="cil-euro" />
          </CButton>
        </td>
      </template>
    </CDataTable>

    <CCard class="actions sticky-bottom">
      <CCardBody class="p-2">
        <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
          <div>
            <h5 class="mt-1">Total: <span class="d-inline count bg-primary pb-1">{{ total }}</span></h5>
          </div>

          <div v-if="pages > 1" class="ml-auto">
            <CPagination
              align="center"
              :dots='false'
              :pages="pages"
              :active-page.sync="activePage"
            />
          </div>
        </div>
      </CCardBody>
    </CCard>

    <payments-aside :invoice.sync="selectedInvoice" />

  </CCardBody>
</template>

<script>
import PaymentsAside from "../asides/Payments";
import _debounce from 'lodash/debounce';

export default {
  name: "BackendTable",
  components: {
    PaymentsAside,
  },
  data() {
    return {
      loadedItems: [],
      selectedInvoice: null,
      fields: [
        { key: "actions",       label: "Actions",     filter: false, sorter: false, _style: "min-width: 100px; width: 100px; text-align: center;" },
        { key: "id",            label: "ID",          filter: false, _style: "min-width: 100px;" },
        { key: "invoice",       label: "Invoice",     filter: false, _style: "min-width: 100px; width: 120px;" },
        { key: "apamount",      label: "Amount",      filter: false, _style: "width: 200px;" },
        { key: "apdate",        label: "Date",        filter: true, _style: "width: 200px;" },
        { key: "apnote",        label: "Notes",       filter: false, _style: "min-width: 200px;" },
        { key: "company",       label: "Company",     filter: false, _style: "min-width: 240px;", sorter: false },
      ],

      activePage: 1,
      pages: 1,
      total: 0,
      items_per_page: null,
      orderBy: null,
      asc: null,
      search: null,
      filteredColumn: null,

      loading: false,
      submitted: false,

      // Alert işlemleri için
      successAlertMessage: {},
      dismissSecs: 10,
      dismissSuccessAlert: 0,
      errorAlertMessage: {},
      showErrorAlert: false,

      // Company Filter işlemleri için
      allCompanies: [],

      // Date Filter
      dateFilter: null,
    };
  },

  async beforeMount() {
    this.filterByDate = _debounce(async () => {
      await this.getAllItems();
    }, 1000);
  },

  async mounted() {
    this.activePage = this.queryPage;

    await this.getAllCompanies();
    if(this.companyId) {
      this.selectedCompany = this.allCompanies.find((c) => c.value == this.companyId)
    }
    await this.getAllItems();
  },

  watch: {
    reloadParams() {
      if(this.queryPage != this.activePage) {
        this.$router.push({ name: this.$route.name, query: { page: this.activePage } });
      }
      this.onTableChange();
    },
    dateFilter() {
      this.filterByDate();
    },

    "selectedInvoice.isPaymentAdded": {
      handler: function (val) {
        val && this.getAllItems()
      },
      deep: true,
    },
  },

  computed: {
    itemsPerPage: {
      get: function () { return this.items_per_page ? this.items_per_page : parseInt(process.env.VUE_APP_ITEMS_PER_PAGE) },
      set: function (newValue) { this.items_per_page = newValue }
    },
    reloadParams() { return [this.activePage] },
    queryPage() { return parseInt(this.$route.query.page) || 1 },
    companyId () { return this.$route.params.company_id },
    selectedCompany: {
      get() { return this.$store.state.filters.menutepay.selectedCompany },
      set(value) { this.$store.state.filters.menutepay.selectedCompany = value },
    }
  },

  methods: {
    async getAllItems() {
      this.loading = true;

      var url = new URL(this.$backend.MENUTEPAY.PAYMENTS.GET_ALL),
        params = {
          page: this.activePage,
          itemsPerPage: this.itemsPerPage,
          search: this.search,
          orderBy: this.orderBy,
          asc: this.asc,
          company: (this.selectedCompany && this.selectedCompany.value) ? this.selectedCompany.value : null,
          dateFilter: this.dateFilter,
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      await this.$axios.get(url)
        .then((response) => {
          return response.data;
        })
        .then((items) => {
          // Son sayfadan daha büyük bir queryPage çağrımı (?page=XXX) yapılırsa;
          if(items.current_page > items.last_page)
            this.activePage = 1;
          else {
            this.loadedItems = items.data;
            this.activePage = items.current_page;
            this.pages = items.last_page;
            this.total = items.total;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getAllCompanies() {
      await this.$axios
        .get(this.$backend.MENUTEPAY.COMPANIES.GET_LIST)
        .then((response) => {
          this.allCompanies = response.data;
        })
        .catch(() => { this.allCompanies = [] })
    },


    onTableChange() { // Active Page güncellendiğinde çağrılıyor.
      this.getAllItems();
    },
    paginationChange(value) { // Items Per Page güncellendiğinde çağrılıyor.
      this.itemsPerPage = value;
      this.getAllItems();
    },
    sorterValue(item) {
      this.orderBy = item.column;
      this.asc = item.column ? item.asc : null; //column null ise asc=null olsun
      this.getAllItems();
    },
    tableSearch(search) {
      this.getAllItems();
    },

    // Filters
    companyFilter(value) {
      this.getAllItems();
    },
    resetFilters() {
      if (this.$store.state.filterShow) {
        this.selectedCompany = null;
        this.getAllItems();
      }
      this.$store.commit('toggle', 'filterShow');
    },

    // Show Payments
    addPayment(invoice) {
      this.selectedInvoice = {
        ...invoice,
        isPaymentAdded: false
      };
      this.$store.commit("toggle", "invoicePaymentsAsideShow");
    },

    // Delete Payment
    deleteItem(item, index) {
      this.$confirm({
        title: 'Confirm',
        message: `⚠️ Do you really want to delete?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            this.loading = true;
            this.$axios
              .delete(this.$backend.MENUTEPAY.PAYMENTS.DELETE.replace("{id}", item?.id))
              .then(this.getAllItems)
              .then(() => {
                // this.$delete(this.loadedItems, index);

                this.showErrorAlert = false
                this.successAlertMessage = {itemName: 'Payment', message: 'deleted.'}
                this.dismissSuccessAlert = this.dismissSecs
                // this.total--
              })
              .catch((error) => {
                this.errorAlertMessage = error.response.data;
                this.showErrorAlert = true;
              })
              .finally(() => {
                window.scrollTo(0,0);
                this.loading = false;
              });
          }
        },
      });
    },
  },
};
</script>

<style>
.table th, .table td {
  padding: 0.5rem 0.25rem;
  vertical-align: middle;
}
@media (min-width: 576px) {
  input[aria-label='table filter input'] {
    width: 280px !important;
  }
}
</style>

<style scoped>
/* Date Filter */
.w-min {
  width: 100px;
}
</style>
