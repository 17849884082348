<template>
  <CSidebar
    aside
    :show="$store.state.invoicePaymentsAsideShow"
    @update:show="(val) => $store.commit('set', ['invoicePaymentsAsideShow', val])"
    colorScheme="light"
    overlaid
    size="lg"
  >
    <CSidebarClose @click.native="$store.commit('toggle', 'invoicePaymentsAsideShow')" />

    <!-- Error Alert -->
    <CListGroup class="list-group-accent mt-5" v-show='showErrorAlert'>
      <CListGroupItem class="list-group-item-accent-secondary bg-light text-danger text-center font-weight-bold text-uppercase">
        Error
      </CListGroupItem>

      <CListGroupItem href="#" class="list-group-item-accent-danger list-group-item-divider">
        <div class="c-avatar float-left mr-2">
          <CIcon name="cil-bug" size="xl" class="c-avatar-img text-danger"/>
        </div>
        <div>{{ errorAlertMessage }}</div>
      </CListGroupItem>
    </CListGroup>
    <!-- / Error Alert -->

    <CListGroup class="scrollable list-group-accent mt-5" v-show='!showErrorAlert'>
      <template v-if="invoice && !invoice.is_paid">
        <CListGroupItem class="mb-0 list-group-item-accent-secondary bg-light text-center font-weight-bold text-uppercase">
          ADD PAYMENT
        </CListGroupItem>
        <CListGroupItem class="list-group-item-accent-success">
          <CInput
            class="mb-1"
            type="number"
            label="Amount:"
            placeholder="Payment amount"
            step="0.01"
            pattern="^\d+(?:\.\d{1,2})?$"
            onblur="(function(el){el.value=parseFloat(el.value).toFixed(2);})(this)"
            :value.sync="form.apamount"
          >
            <template #prepend-content><CIcon name="cil-euro" /></template>
          </CInput>

          <CInput
            class="mb-1"
            type="date"
            label="Payment Date:"
            :value.sync="form.apdate"
            :max="new Date().toISOString().slice(0,10)"
          />

          <CTextarea
            class="mb-1"
            rows="2"
            placeholder="Note"
            :value.sync="form.apnote"
          />

          <CButton
            type="submit"
            color="success"
            size="sm"
            shape="pill"
            @click="addPayment"
          >
            <CIcon name="cil-plus" /> Add
          </CButton>
        </CListGroupItem>
      </template>

      <CListGroupItem class="list-group-item-accent-secondary bg-light text-center font-weight-bold text-uppercase">
        Invoice Payments
      </CListGroupItem>

      <template v-for="p in invoice?.payments">
        <CListGroupItem class="list-group-item-divider list-group-item-accent-dark">
          <div class="d-flex">
            {{ p.apamount | toCurrency }}
            <div class="ml-auto small text-muted">
              {{ moment(p.apdate).format('DD/MM/YYYY') }}
            </div>
          </div>
        </CListGroupItem>
      </template>

      <!-- Total Payment -->
      <CListGroupItem class="list-group-item-divider" :class="`list-group-item-accent-${invoice?.is_paid ? 'success' : 'warning'}`">
        <div class="d-flex">
          <strong>{{ invoice?.total_payment | toCurrency }}</strong>
          <div class="ml-auto">
            <strong><CIcon name="cil-arrow-left" class="mr-2" size="lg" /> TOTAL</strong>
          </div>
        </div>
      </CListGroupItem>

      <!-- Payable -->
      <CListGroupItem class="list-group-item-divider list-group-item-accent-info">
        <div class="d-flex">
          <strong>{{ invoice?.ivTotIncl | toCurrency }}</strong>
          <div class="ml-auto">
            <strong><CIcon name="cil-arrow-left" class="mr-2" size="lg" /> PAYABLE</strong>
          </div>
        </div>
      </CListGroupItem>

      <!-- Balance -->
      <CListGroupItem class="list-group-item-divider" :class="`list-group-item-accent-${invoice?.is_paid ? 'success' : 'warning'}`">
        <div class="d-flex">
          <h5>{{ balance | toCurrency('signDisplay') }}</h5>
          <div class="ml-auto">
            <h5><CIcon name="cil-arrow-left" class="mr-2" size="lg" /> BALANCE</h5>
          </div>
        </div>
      </CListGroupItem>
    </CListGroup>

    <CElementCover :opacity="0.4" v-show="loading"/>

  </CSidebar>
</template>

<script>
export default {
  name: "PaymentsAside",
  props: {
    invoice: {
      type: Object,
    },
  },
  components: {
  },
  data() {
    return {
      loading: false,
      payments: [],

      errorAlertMessage: '',
      showErrorAlert: false,
      form: null
    };
  },
  watch: {
    reloadParams() {
      this.$store.state.invoicePaymentsAsideShow;
      this.form = {
        apamount: Math.abs(this.balance).toFixed(2),
        apdate: new Date().toISOString().slice(0,10),
        apnote: null
      }
    },
  },
  computed: {
    reloadParams() {
      return [this.$store.state.invoicePaymentsAsideShow];
    },
    balance() {
      return this.invoice ? (this.invoice.total_payment - this.invoice.ivTotIncl) : 0;
    }
  },

  methods: {
    addPayment() {
      this.form.company_id = this.invoice?.ivCompanyId;
      this.form.invoice_id = this.invoice?.id;

      this.loading = true;

      this.$axios
        .post(this.$backend.MENUTEPAY.PAYMENTS.STORE, this.form)
        .then((response) => {
          this.$toast.success(`Payment added successfully.`);
          this.$store.commit('set', ['invoicePaymentsAsideShow', false]);
          this.invoice.isPaymentAdded = true;
        })
        .catch((error) => {
          this.$toast.error(`${error.response.data.message}`);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  }
};
</script>

<style scoped>
.scrollable {
  overflow-y: auto;
  height: 100%;
}
</style>
