<template>
  <CRow>
    <CCol sm="12">
      <CCard>

        <CCardHeader>
          <CIcon name="cil-euro" />
          <h5 class="d-inline ml-2">MenutePay Payments</h5>
          <div class="card-header-actions">
            <CButton color="primary" :to="{ name: 'MenutePay Invoices'}">
              <CIcon name="cil-plus" class="align-bottom" /><strong>Add </strong> Payment
            </CButton>

            <CButton v-if="companyId" color="link" :to="{name: 'View Company', params: {'id': companyId}}" class="ml-2">
              <CIcon name="cil-arrow-thick-left" class="align-bottom" /> Back To Company
            </CButton>
          </div>
        </CCardHeader>

        <BackendTable />
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import BackendTable from "./BackendTable";

export default {
  name: "MenutePayPayments",
  components: {
    BackendTable,
  },
  computed: {
    companyId() { return this.$route.params.company_id; },
  },
};
</script>
